import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button, Input, Flex, Table, Typography, Tag, Drawer, Result } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/es/table'

import { AddCompany } from '../setup'
import { ColorNumber } from 'components/ColorNumber'
import { Page } from 'components/Page'
import { FullLoader } from 'components/FullLoader'

import { formatDateTime } from 'utils/dates'
import { useCompanyStore } from 'store/company.store'
import { useCompanies } from 'hooks/useCompanies'
import { useLastCompaniesReviews } from 'hooks/useCompanies'
import { useAccessLevel } from 'hooks/useAccessLevel'
import { useRole } from 'hooks/useRole'
import { RoleType } from 'types/user.types'
import type { ICompany } from 'types/company.types'

const { Search } = Input
const { Text } = Typography

export function AllCompaniesPage() {
  const navigate = useNavigate()
  const { isAllowed } = useRole(RoleType.Owner)
  const { accessLevel, isLoading } = useAccessLevel()

  const companyId = useCompanyStore((state) => state.id)
  const companies = useCompanies()
  const reviews = useLastCompaniesReviews()

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')

  const dataSource = companies.data?.map((company) => ({
    ...company,
    review: reviews.data?.find((v: any) => v.company.id === company.id)?.updatedAt,
    accuracy: reviews.data?.find((v: any) => v.company.id === company.id)?.rating,
  }))

  const selected = companyId ? [companyId] : []

  useEffect(() => {
    setTimeout(() => {
      companies.revalidate()
      reviews.revalidate()
    }, 2500)
  }, [])

  if (isLoading) return <FullLoader />
  if (!accessLevel) return <NoSubsription isAdmin={isAllowed} />

  if (!!companies.data && !companies.data.length && isAllowed)
    return (
      <Page title="All Companies">
        <Result
          status="success"
          title="Welcome to the Equility!"
          subTitle="Add a company to get started"
          extra={
            <Button type="primary" onClick={() => setOpen(true)}>
              Add Company
            </Button>
          }
        />
        <Drawer
          title="Add company"
          placement="right"
          width={'100%'}
          open={open}
          onClose={() => setOpen(false)}
          children={<AddCompany onClose={() => setOpen(false)} id={1} />}
        />
      </Page>
    )

  const filteredData = dataSource?.filter((company) => {
    const name = company.alias || company.name
    return name.toLowerCase().includes(search.toLowerCase())
  })

  return (
    <Page title="All Companies">
      <Flex vertical gap={10}>
        <Flex style={headerStyle} gap={20} align="center" justify="space-between">
          <Search
            style={searchStyle}
            placeholder="Search"
            value={search}
            onChange={(v) => setSearch(v.target.value)}
            allowClear
            loading={!!search}
          />
          {isAllowed && (
            <Button type="primary" onClick={() => setOpen(true)}>
              Add Company
            </Button>
          )}
        </Flex>
        
          <Table
            loading={companies.isLoading || reviews.isLoading}
            scroll={{x:950,y:"calc(100vh - 220px)" }}
            dataSource={filteredData}
            columns={columns}
            rowKey="id"
            pagination={false}
            rowSelection={{
              hideSelectAll: true,
              selectedRowKeys: selected,
              columnWidth: 0,
              renderCell: () => null,
            }}
            onRow={(record) => {
              return {
                onClick: () => {
                  navigate(`/company/${record.id}`)
                },
              }
            }}
          />
        
      </Flex>
      <Drawer
        title="Add company"
        placement="right"
        width={'100%'}
        open={open}
        onClose={() => setOpen(false)}
        children={<AddCompany onClose={() => setOpen(false)} id={1} />}
      />
    </Page>
  )
}

const columns: ColumnsType<ICompany> = [
  {
    title: 'Company Name',
    dataIndex: ['name'],
    ellipsis: true,
    onCell: () => ({ style: { cursor: 'pointer' } }),
    render: (name, company) => company.alias || name,
    sorter: (a, b) => {
      const name = a.alias || a.name
      return name.localeCompare(b.alias || b.name)
    },
  },
  {
    title: 'QuickBooks',
    dataIndex: ['connections'],
    ellipsis: true,
    width: 300,
    render: (_, company) => company.name,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Tier',
    dataIndex: ['accessLevel'],
    width: 120,
    render: (accessLevel) => {
      if (accessLevel === 0) return 'Free'
      if (accessLevel === 1) return 'Pro'
      return 'Advanced'
    },
  },
  {
    title: 'Last Financial Review',
    dataIndex: ['review'],
    width: 360,
    render: (review, record: any) => {
      if (!review) return <Text type="secondary">Never</Text>
      return (
        <Flex gap={10}>
          <ColorNumber amount={record.accuracy} min={100} type="percent" />
          <Text type="secondary">
            <small>{formatDateTime(review)}</small>
          </Text>
        </Flex>
      )
    },
  },
  {
    title: 'Status',
    dataIndex: ['status'],
    width: 150,
    render: (_, company) => {
      const intergateStatuses = company.connections.map((integration) => integration.status)
      const isDisconnected = intergateStatuses.some((status) => status === 0)
      if (isDisconnected) return <FailTag />
      const isPending = intergateStatuses.some((status) => status === 2)
      if (isPending) return <PendingTag />
      return <PassTag />
    },
  },
]

const headerStyle: React.CSSProperties = {
  marginTop: 10,
}

const searchStyle: React.CSSProperties = {
  width: 300,
}

const PassTag = () => {
  return (
    <Tag icon={<CheckCircleOutlined />} color="green">
      Active
    </Tag>
  )
}

const FailTag = () => {
  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      Disconnected
    </Tag>
  )
}

const PendingTag = () => {
  return (
    <Tag icon={<SyncOutlined spin />} color="processing">
      Syncing
    </Tag>
  )
}

const NoSubsription = ({ isAdmin }: { isAdmin: boolean }) => {
  return (
    <Result
      status="warning"
      title="Please choose a subscription plan"
      subTitle={
        isAdmin
          ? 'Thank for using Equility! We hope that you enjoyed your free trial. To continue to access your data, please update your subscription plan using the link below.'
          : 'Thank for using Equility! Please contact your account manager to get access to the platform.'
      }
      extra={
        isAdmin && (
          <Button type="primary">
            <NavLink to="/account/billing">Update Subscription</NavLink>
          </Button>
        )
      }
    />
  )
}
