import { useState } from 'react'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Flex, notification, Typography } from 'antd'
import { Button, ButtonProps, Card, Col, Divider, Form, Input, Row } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'

import { AuthPage } from './components/AuthPage'
import { AuthWelcome } from './components/Welcome'
import { AppFooter } from 'app/AppFooter'

import { rules } from 'utils/form'
import { useAuthUser } from 'hooks/useAuth'
import { useAuthStore } from 'store/auth.store'
import { signInWithGoogle } from 'utils/firebase'
import { signInWithPassword } from 'utils/firebase'
import { getErrorMsg } from 'utils/geterror'
import { api } from 'utils/axios'

const { Text } = Typography

export function SignInPage() {
  const isAuth = useAuthStore((state) => !!state.uid)
  const { authUser } = useAuthUser()

  const [loading, setLoading] = useState(false)

  const [searchParams] = useSearchParams()
  const invitationTokenParam = searchParams.get('token')
  const emailParam = searchParams.get('email') || ''

  const signIn = async (form: any) => {
    setLoading(true)
    try {
      const { user } = await signInWithPassword(form.email, form.password)
      if(invitationTokenParam){
          if (!user.email) throw new Error('Email is required')
          const body = {
            email: user.email,
            token: await user.getIdToken(),
            invitationToken: invitationTokenParam,
          }
          api
          .post('/auth/accept-invite', body)
          .then(() =>
            notification.success({
              message: 'Invite accepted',
              description: 'The invite has been accepted and you joined a new team.',
            }),
          )
          .catch((err) => notification.error(getErrorMsg(err)))
          .finally(() => setLoading(false))
        }
    } catch (error) {
      notification.error(getErrorMsg(error))
    } finally {
      setLoading(false)
    }
  }

  const googleSignIn = async () => {
    setLoading(true)
    try {
      const { user } = await signInWithGoogle()
      if (!user.email) throw new Error('Email is required')
      const body = {
        email: user.email,
        fullName: user.displayName,
        token: await user.getIdToken(),
        invitationToken: invitationTokenParam,
        googleSignIn: true,
      }
      await authUser(body)
    } catch (error) {
      notification.error(getErrorMsg(error))
    } finally {
      setLoading(false)
    }
  }

  if (isAuth) return <Navigate to="/" />

  const btnProps: ButtonProps = {
    loading,
    block: true,
    type: 'primary',
  }

  return (
    <AuthPage>
      <Helmet>
        <title>Equility - Sign In</title>
      </Helmet>
      <Row style={contentStyle}>
        <Col span={12}>
          <AuthWelcome />
        </Col>
        <Col span={12}>
          <Card title="Sign In">
            <Button {...btnProps} onClick={googleSignIn} icon={<GoogleOutlined />}>
              Sign In with Google
            </Button>
            <Divider>or</Divider>
            <Form onFinish={signIn} labelCol={{ span: 8 }} initialValues={{ email: emailParam }}>
              <Form.Item name="email" rules={rules.email}>
                <Input placeholder="Email" />
              </Form.Item>
              <Form.Item name="password" rules={rules.password}>
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Button {...btnProps} htmlType="submit">
                  Sign In
                </Button>
              </Form.Item>
            </Form>
            <Flex gap={4}>
              <Link to="/forgot">Forgot your password?</Link>
            </Flex>
            <Flex gap={4}>
              <Text>New customer? </Text>
              <Link to="/signup">Start here</Link>
            </Flex>
          </Card>
        </Col>
        <Col span={24}>
          <AppFooter />
        </Col>
      </Row>
    </AuthPage>
  )
}

const contentStyle = {
  width: 800,
}
